import React from 'react';
import PropTypes from 'prop-types';

import '../../styles/main.scss';
import data from '../../data/layout/content.json';

import Header from './header';
import Footer from './footer';
import Heading from '../molecules/heading';
import BodyCopy from '../atoms/body-copy';
import Title from '../atoms/title';
import Button from '../atoms/button';

const Layout = ({ 
	children,
	type,
	content,
	classNames = false,
	hideSecureForm = false,
	showBG = false,
	showFormSecureBanner = false
}) => {
	const createClassList = () => {
		let classList = 'l-wrapper';
		if (showBG) classList = classList + ' l-wrapper--bg';
		if (classNames) classList = classList + ' ' + classNames;
		return classList;
	};
	return (
		<div className={createClassList()}>
			<Header
				type={type}
				content={(content && content.header) ? content.header : data.header}
				hideSecureForm={hideSecureForm}
				showFormSecureBanner={showFormSecureBanner}
			/>
			<main>
				<div id='mas-informacion' className='background-wrapper'>
					<div className='background-container'></div>
					<div className='l-container'>{children}</div>
				</div>
				<div className='wrapper'>
					<div className='l-container'>
						<div className='c-duo-section'>
							<div className='c-duo-section__col'>
								<iframe src="https://player.vimeo.com/video/926716926?h=e6209277d7" width="640" height="360" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
							</div>
							<div className='c-duo-section__col'>
								<Heading 
									title="Ofertas De Inversión En Construcción Previa"
									align="center"
									color="dark"
								/>
								<BodyCopy
									copy={[
										"Invertir en tu propia propiedad de alquiler a corto plazo en lugar de un fondo tiene ventajas distintas. Con la propiedad individual, tienes control sobre las decisiones de la propiedad, el potencial de apreciación de la propiedad y la capacidad de adaptar la propiedad a tus preferencias.",
										"A diferencia de un fondo con múltiples inversores, disfrutas de todos los beneficios financieros y participación directa en el éxito de tu inversión. Se trata de maximizar los rendimientos, el control y la satisfacción de ver cómo tu propiedad prospera de manera independiente."
									]}
								/>
								<Heading 
									subTitle='Compra directamente al desarrollador.'
									align="center"
									color="dark"
								/>
							</div>
						</div>
					</div>
					<div className='l-container l-container__bg'>
						<div className='c-duo-section'>
							<div className='c-duo-section__col'>
								<BodyCopy
									align='center'
									copy={[
										"¡Descubre la oportunidad de inversión definitiva en Miami, FL! Vida Edgewater Hotel & Residences es tu puerta al éxito.",
										"Un edificio aprobado por Airbnb con un componente hotelero, es la combinación perfecta para una inversión ganadora.",
										"Aumenta tus retornos con unidades únicas con bloqueo, diseñadas para maximizar tus ingresos por alquiler.",
										"Con un hotelero experimentado gestionando tu unidad, el camino hacia los retornos mensuales nunca ha sido más fácil.",
										"¡Aprovecha la oportunidad de convertir tu estancia en Miami en una inversión lucrativa con Vida Edgewater!"
									]}
								/>
							</div>
							<div className='c-duo-section__col'>
								<video crossOrigin="anonymous" playsInline preload="auto" muted={true} loop autoPlay src="https://video.wixstatic.com/video/b58646_14cd3f1fa91c4838986ca95efa31de0d/480p/mp4/file.mp4"></video>
							</div>
						</div>
					</div>
					<div className='l-container'>
						<Heading 
							title="ÚLTIMAS PROPIEDADES EN VENTA"
							subTitle='!Financiamiento hasta 80% para extranjeros¡'
							align="center"
							color="dark"
						/>
						{/* property 1 */}
						<div className='c-property'>
							<div className='c-section'>
								<div className='c-section__col'>
									<img src={require(`../../images/ve/floorplan1.png`)} className='c-property__image c-property__image--floorplan'/>
								</div>
								<div className='c-section__col'>
									<Title 
										title='PROPIEDAD: ESTUDIO'
										type='h4'
									/>
									<div className='c-property__information'>
										<div className='c-property__copy'>
											<Title 
												title='DETALLES'
												type='h5'
											/>
											<p>Interior: 387sqft/36m2</p>
											<p>Exterior: 32,5sf/3m2</p>
											<p>Total: 419,5sf/39m2</p>
										</div>
										<div className='c-property__copy'>
											<Title 
												title='MONTO DE INVERSIÓN'
												type='h5'
											/>
											<p>$100,000 A $200,000</p>
										</div>
										<div className='c-property__copy'>
											<Title 
												title='PRECIO DE VENTA'
												type='h5'
											/>
											<p>$450,000 A $550,000</p>
										</div>
										<div className='c-property__copy'>
											<Title 
												title='RENDIMIENTO OBJETIVO'
												type='h5'
											/>
											<p>EFECTIVO SOBRE EFECTIVO</p>
											<p>9.5 %  A 15.3%</p>
										</div>
									</div>
									<div className='c-property__cta'>
									<Button
										classNames='c-property__button'
										type='primary'
										label='MÁS INFORMACIÓN'
										// onClick={(e) => window.location.href = this.state.content.button.url}
										url='#mas-informacion'
										large
									/>
									</div>
								</div>
								<div className='c-section__col'>
									<img src={require(`../../images/ve/property1.png`)} className='c-property__image'/>
									<span className='c-property__image-tag'>SOLO QUEDAN 3</span>
								</div>
							</div>
						</div>

						{/* property 2 */}
						<div className='c-property'>
							<div className='c-section'>
								<div className='c-section__col'>
									<img src={require(`../../images/ve/floorplan2.png`)} className='c-property__image c-property__image--floorplan'/>
								</div>
								<div className='c-section__col'>
									<Title 
										title='PROPIEDAD: 1 HABITACIÓN 1 BAÑO'
										type='h4'
									/>
									<div className='c-property__information'>
										<div className='c-property__copy'>
											<Title 
												title='DETALLES'
												type='h5'
											/>
											<p>Interior: 459SF/42,6m2</p>
											<p>Exterior: 43SF/4M2</p>
											<p>Total: 502sf/46,6m2</p>
										</div>
										<div className='c-property__copy'>
											<Title 
												title='MONTO DE INVERSIÓN'
												type='h5'
											/>
											<p>$200,000 A $300,000</p>
										</div>
										<div className='c-property__copy'>
											<Title 
												title='PRECIO DE VENTA'
												type='h5'
											/>
											<p>$550,000 A $650,000</p>
										</div>
										<div className='c-property__copy'>
											<Title 
												title='RENDIMIENTO OBJETIVO'
												type='h5'
											/>
											<p>EFECTIVO SOBRE EFECTIVO</p>
											<p>9.5 %  A 15.3%</p>
										</div>
									</div>
									<div className='c-property__cta'>
									<Button
										classNames='c-property__button'
										type='primary'
										label='MÁS INFORMACIÓN'
										// onClick={(e) => window.location.href = this.state.content.button.url}
										url='#mas-informacion'
										large
									/>
									</div>
								</div>
								<div className='c-section__col'>
									<img src={require(`../../images/ve/property2.png`)} className='c-property__image'/>
									<span className='c-property__image-tag'>AGOTADO</span>
								</div>
							</div>
						</div>

						{/* property 3 */}
						<div className='c-property'>
							<div className='c-section'>
								<div className='c-section__col'>
									<img src={require(`../../images/ve/floorplan3.png`)} className='c-property__image c-property__image--floorplan'/>
								</div>
								<div className='c-section__col'>
									<Title 
										title='PROPIEDAD: 2 HABITACIONES 2 BAÑOS UNIDAD CON BLOQUEO'
										type='h4'
									/>
									<div className='c-property__information'>
										<div className='c-property__copy'>
											<Title 
												title='DETALLES'
												type='h5'
											/>
											<p>Interior: 459SF/42,6m2</p>
											<p>Exterior: 43SF/4M2</p>
											<p>Total: 502sf/46,6m2</p>
										</div>
										<div className='c-property__copy'>
											<Title 
												title='MONTO DE INVERSIÓN'
												type='h5'
											/>
											<p>$250,000 A $350,000</p>
										</div>
										<div className='c-property__copy'>
											<Title 
												title='PRECIO DE VENTA'
												type='h5'
											/>
											<p>$850,000 A $950,000</p>
										</div>
										<div className='c-property__copy'>
											<Title 
												title='RENDIMIENTO OBJETIVO'
												type='h5'
											/>
											<p>EFECTIVO SOBRE EFECTIVO</p>
											<p>9.5 %  A 15.3%</p>
										</div>
									</div>
									<div className='c-property__cta'>
									<Button
										classNames='c-property__button'
										type='primary'
										label='MÁS INFORMACIÓN'
										// onClick={(e) => window.location.href = this.state.content.button.url}
										url='#mas-informacion'
										large
									/>
									</div>
								</div>
								<div className='c-section__col'>
									<img src={require(`../../images/ve/property3.png`)} className='c-property__image'/>
									<span className='c-property__image-tag'>SOLO QUEDAN 2</span>
								</div>
							</div>
						</div>

						{/* property 4 */}
						<div className='c-property'>
							<div className='c-section'>
								<div className='c-section__col'>
									<img src={require(`../../images/ve/floorplan4.png`)} className='c-property__image c-property__image--floorplan'/>
								</div>
								<div className='c-section__col'>
									<Title 
										title='PROPIEDAD: UNIDAD CON BLOQUEO DE 2 HABITACIONES Y 2 BAÑOS CON LANAI'
										type='h4'
									/>
									<div className='c-property__information'>
										<div className='c-property__copy'>
											<Title 
												title='DETALLES'
												type='h5'
											/>
											<p>Interior: 970sf/90m2</p>
											<p>Exterior: 1053sf/97,8m2</p>
											<p>Total: 2023sf/187,9m2</p>
										</div>
										<div className='c-property__copy'>
											<Title 
												title='MONTO DE INVERSIÓN'
												type='h5'
											/>
											<p>$350,000 A $450,000</p>
										</div>
										<div className='c-property__copy'>
											<Title 
												title='PRECIO DE VENTA'
												type='h5'
											/>
											<p>$850,000 A $950,000</p>
										</div>
										<div className='c-property__copy'>
											<Title 
												title='RENDIMIENTO OBJETIVO'
												type='h5'
											/>
											<p>EFECTIVO SOBRE EFECTIVO</p>
											<p>9.5 %  A 15.3%</p>
										</div>
									</div>
									<div className='c-property__cta'>
									<Button
										classNames='c-property__button'
										type='primary'
										label='MÁS INFORMACIÓN'
										// onClick={(e) => window.location.href = this.state.content.button.url}
										url='#mas-informacion'
										large
									/>
									</div>
								</div>
								<div className='c-section__col'>
									<img src={require(`../../images/ve/property4.png`)} className='c-property__image'/>
									<span className='c-property__image-tag'>SOLO QUEDA 1</span>
								</div>
							</div>
						</div>

						{/* property 5 */}
						<div className='c-property'>
							<div className='c-section'>
								<div className='c-section__col'>
									<img src={require(`../../images/ve/floorplan5.png`)} className='c-property__image c-property__image--floorplan'/>
								</div>
								<div className='c-section__col'>
									<Title 
										title='PROPIEDAD: UNIDAD CON BLOQUEO DE 2 HABITACIONES Y 2 BAÑOS CON LANAI'
										type='h4'
									/>
									<div className='c-property__information'>
										<div className='c-property__copy'>
											<Title 
												title='DETALLES'
												type='h5'
											/>
											<p>Interior: 774sf/71,9m2</p>
											<p>Exterior: 682sf/63,4m2</p>
											<p>Total: 1456sf/135,3m2</p>
										</div>
										<div className='c-property__copy'>
											<Title 
												title='MONTO DE INVERSIÓN'
												type='h5'
											/>
											<p>$350,000 A $450,000</p>
										</div>
										<div className='c-property__copy'>
											<Title 
												title='PRECIO DE VENTA'
												type='h5'
											/>
											<p>$850,000 A $950,000</p>
										</div>
										<div className='c-property__copy'>
											<Title 
												title='RENDIMIENTO OBJETIVO'
												type='h5'
											/>
											<p>EFECTIVO SOBRE EFECTIVO</p>
											<p>9.5 %  A 15.3%</p>
										</div>
									</div>
									<div className='c-property__cta'>
									<Button
										classNames='c-property__button'
										type='primary'
										label='MÁS INFORMACIÓN'
										// onClick={(e) => window.location.href = this.state.content.button.url}
										url='#mas-informacion'
										large
									/>
									</div>
								</div>
								<div className='c-section__col'>
									<img src={require(`../../images/ve/property5.png`)} className='c-property__image'/>
									<span className='c-property__image-tag'>SOLO QUEDAN 3</span>
								</div>
							</div>
						</div>

						{/* property 6 */}
						<div className='c-property'>
							<div className='c-section'>
								<div className='c-section__col'>
									<img src={require(`../../images/ve/floorplan6.png`)} className='c-property__image c-property__image--floorplan'/>
								</div>
								<div className='c-section__col'>
									<Title 
										title='PROPIEDAD: UNIDAD CON BLOQUEO DE 3 HABITACIONES Y 3 BAÑOS'
										type='h4'
									/>
									<div className='c-property__information'>
										<div className='c-property__copy'>
											<Title 
												title='DETALLES'
												type='h5'
											/>
											<p>Interior: 1233sf/114,6m2</p>
											<p>Exterior: 193sf/18m2</p>
											<p>Total: 1426sf/132,6m2</p>
										</div>
										<div className='c-property__copy'>
											<Title 
												title='MONTO DE INVERSIÓN'
												type='h5'
											/>
											<p>$480,000 A $650,000</p>
										</div>
										<div className='c-property__copy'>
											<Title 
												title='PRECIO DE VENTA'
												type='h5'
											/>
											<p>$1.550,000 A $1.850,000</p>
										</div>
										<div className='c-property__copy'>
											<Title 
												title='RENDIMIENTO OBJETIVO'
												type='h5'
											/>
											<p>EFECTIVO SOBRE EFECTIVO</p>
											<p>9.5 %  A 15.3%</p>
										</div>
									</div>
									<div className='c-property__cta'>
									<Button
										classNames='c-property__button'
										type='primary'
										label='MÁS INFORMACIÓN'
										// onClick={(e) => window.location.href = this.state.content.button.url}
										url='#mas-informacion'
										large
									/>
									</div>
								</div>
								<div className='c-section__col'>
									<img src={require(`../../images/ve/property6.png`)} className='c-property__image'/>
									<span className='c-property__image-tag'>SOLO QUEDAN 3</span>
								</div>
							</div>
						</div>

						{/* property 7 */}
						<div className='c-property'>
							<div className='c-section'>
								<div className='c-section__col'>
									<img src={require(`../../images/ve/floorplan7.png`)} className='c-property__image c-property__image--floorplan'/>
								</div>
								<div className='c-section__col'>
									<Title 
										title='PROPIEDAD: UNIDAD CON BLOQUEO DE 3 HABITACIONES Y 3 BAÑOS'
										type='h4'
									/>
									<div className='c-property__information'>
										<div className='c-property__copy'>
											<Title 
												title='DETALLES'
												type='h5'
											/>
											<p>Interior: 1681sf/156,1m2</p>
											<p>Exterior: 258sf/24m2</p>
											<p>Total: 1939sf/180,1m2</p>
										</div>
										<div className='c-property__copy'>
											<Title 
												title='MONTO DE INVERSIÓN'
												type='h5'
											/>
											<p>$570,000 A $750,000</p>
										</div>
										<div className='c-property__copy'>
											<Title 
												title='PRECIO DE VENTA'
												type='h5'
											/>
											<p>$1.900,000 A $2.200,000</p>
										</div>
										<div className='c-property__copy'>
											<Title 
												title='RENDIMIENTO OBJETIVO'
												type='h5'
											/>
											<p>EFECTIVO SOBRE EFECTIVO</p>
											<p>9.5 %  A 15.3%</p>
										</div>
									</div>
									<div className='c-property__cta'>
									<Button
										classNames='c-property__button'
										type='primary'
										label='MÁS INFORMACIÓN'
										// onClick={(e) => window.location.href = this.state.content.button.url}
										url='#mas-informacion'
										large
									/>
									</div>
								</div>
								<div className='c-section__col'>
									<img src={require(`../../images/ve/property7.png`)} className='c-property__image'/>
									<span className='c-property__image-tag'>SOLO QUEDAN 3</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
			<Footer 
				pageDisclaimer={(content && content.footer && content.footer.pageDisclaimer) ? content.footer.pageDisclaimer : false}
			/>
		</div>
	)
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
