import React from 'react';
import Link from '../atoms/link';

const Footer = ({
	pageDisclaimer = false
}) => {
	const pageDisclaimerContent = (pageDisclaimer) ? (
		<p>{pageDisclaimer}</p>
	) : null;

	return (
		<div className='co-footer'>
			<div className='co-footer__main'>
				<div className='l-container'>
					<div className='l-grid__row l-grid__middle-xs co-footer__logo-links'>
						<div className='l-grid__col-md co-footer__links--secondary'>
							<Link url='https://www.landing.vidaedgewater.com/es/privacy-policy' target="_blank" classNames='co-footer__link'>
								Política de Privacidad
							</Link>
							<Link url='https://www.landing.vidaedgewater.com/es/terms-and-conditions/' target="_blank" classNames='co-footer__link'>
								Terminos & Condicones
							</Link>
							{/* <Link url='#' target="_blank" classNames='co-footer__link'>
								Contact Us
							</Link> */}
							{/* <Link url='/marketing-partners/' classNames='co-footer__link'>
								Marketing Partners
							</Link> */}
						</div>
					</div>
					<div className='l-grid__row'>
						<div className='l-grid__col-xs-12 l-spacing__m-t--24 co-footer__disclaimer'>
							{/* {pageDisclaimerContent} */}
							{/* <p>[--Placeholder-Disclaimer-1--]</p>
							<br />
							<p>[--Placeholder-Disclaimer-2--]</p> */}
							<p>DESCARGO DE RESPONSABILIDAD</p>
							<p>Este es el sitio web oficial de Urbana Bueno DBA Vida Edgewater Hotel & Residences, el sitio de su Socio de Ventas Autorizado (Urbana Bueno DBA Vida Edgewater Hotel & Residences). Podemos enviar actualizaciones al número de móvil/correo electrónico registrado con nosotros. El contenido es solo con fines informativos y no constituye una oferta para aprovechar ningún servicio. Los precios mencionados están sujetos a cambios sin previo aviso y las propiedades mencionadas están sujetas a disponibilidad. Las imágenes son solo para fines de representación. Los logotipos e imágenes utilizados en este sitio web son propiedad exclusiva de Urbana Bueno DBA Vida Edgewater Hotel & Residences y están protegidos por las leyes de derechos de autor aplicables. No reclamamos ninguna propiedad o derechos sobre estos materiales, y se utilizan en este sitio web únicamente con fines informativos.</p>
						</div>
					</div>
				</div>
			</div>
			<div className='co-footer__copyright'>
				<div className='l-container'>
					<div className='l-grid__row'>
						<div className='l-grid__col-xs'>
							<p>&copy; Copyright {new Date().getFullYear()} Vida Edgewater. Todos Los Derechos Reservados.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
